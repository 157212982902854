<template>
    <div>
        <v-row align="center" class="mb-3">
            <v-col cols="12" md="2">
                <v-btn @click="openAddDialog()" color="primary secondary--text" large class="btn">
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>انشاء {{ this.$route.meta.single }}</h3>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="3">
                <v-text-field
                    v-debounce:500ms="getItems"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    hide-details=""
                    prepend-inner-icon="search"
                    dense
                    outlined
                    filled
                />
            </v-col>
        </v-row>
        <v-card id="card">
            <v-data-table
                class="mytable"
                :items-per-page="$global.state.filter.pageSize"
                :items="$global.state.authGroups"
                :loading="$global.state.loading"
                :headers="headers"
                hide-default-footer
                loading-text="جاري تحميل البيانات"
                no-data-text="لا توجد بيانات"
                :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

                <template v-slot:item.roles="{ item }">
                    <v-chip v-for="(role, index) in item.roles" :key="index" class="mr-2">
                        {{ role.roleName }}
                    </v-chip>
                </template>
                
                <template v-slot:item.actions="{ item, index }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                                <v-icon color="error"> delete </v-icon>
                            </v-btn>
                        </template>
                        <span>حذف</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="openEditDialog(item)" v-on="on" icon>
                                <v-icon color="info"> edit </v-icon>
                            </v-btn>
                        </template>
                        <span>تعديل</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            headers: [
                {
                    text: "إسم المجموعة",
                    value: "name",
                },
                {
                    text: "وصف",
                    value: "description",
                },
                {
                    text: "الصلاحيات",
                    value: "roles",
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },               
            ],
        };
    },

    mounted() {
        this.getItems();

        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(`get${this.$route.meta.endPoint}`);
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.authGroups.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
